<template>
  <div class="h-screen">
    <div class="h-full bg-addEstate">
      <div class="mx-4 absolute bottom-12">
        <p class="text-center text-white mb-20">آگهی شما با موقیت ثبت شد</p>
        <img class="-mb-3 w-5/6 mx-auto" v-show="isVilaiee" src="../../assets/images/vilaei.png" alt="vilaei image">
        <img class="-mb-3 w-5/6 mx-auto" v-show="isAparteman" src="../../assets/images/aparteman.png" alt="aparteman image">
        <img class="-mb-3 w-5/6 mx-auto" v-show="isTejari" src="../../assets/images/tejari.png" alt="tejari image">
        <img class="-mb-3 w-5/6 mx-auto" v-show="isShow" src="../../assets/images/digitalmarket.png" alt="digitalmarket image">
        <button type="submit" @click="handleToHome" class="btn-custom  w-full">بازگشت به خانه
          <span class="svg-btn-icon bg-orange">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 24.826) rotate(-90)" fill="#fafafa"/>
</svg>
        </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LastStepAddEstate",
  data(){
    return{
      isAparteman : false,
      isTejari : false,
      isVilaiee : false,
      isShow : false
    }
  },
  methods:{
    handleToHome(){
      this.$store.commit('general/CHANGE_IS_SHOW_BACK', false)
      this.$store.commit('addEstate/CLEAR_ESTATE')
      this.$store.commit('map/CLEAR_SELECT_LOCATION')
      this.$router.push({name : 'HomeRealEstate'})
    }
  },
    mounted() {
    let houseTypes =  this.$store.state.addEstate.house_type
    let usages =  this.$store.state.addEstate.usage
    console.log(houseTypes)
    console.log(usages)
    if (houseTypes === 'آپارتمان'){
      this.isAparteman = true
    }else if(houseTypes === 'ویلایی'){
      this.isVilaiee = true
    }else{
      usages.forEach((item)=>{
        if (item === 'اداری' || item === 'جاری'){
          this.isTejari = true
        }else {
          this.isShow = true
        }
      })
    }

  }
}
</script>

<style scoped>

</style>